<template>
  <v-input>
    <v-card elevation="3" style="width:100%">
      <v-card-text>
        <v-text-field :value="input.label" :label="inputVal.settings.showLabel ? 'Block Label (visible to user)' : 'Block Name (not visible to user)'" @change="(label) => $emit('change', { label })">
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="$emit('remove')" color="error">fal fa-times-circle</v-icon>
              </template>
              <span>Remove Block</span>
            </v-tooltip>
          </template>
          <template v-slot:append-outer>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="$emit('edit')">fal fa-pencil</v-icon>
              </template>
              <span>Edit Removable Block Settings</span>
            </v-tooltip>
          </template>
        </v-text-field>
        <v-row>
          <v-col v-for="(item, index) in inputVal.inputs" :key="'subitem-' + item._id" cols="12" :md="item.md" :lg="item.lg">
            <html-block v-if="item.input === 'html-block'" :input="item" @change="(val) => updateField(val, index)" @remove="removeField(index)"></html-block>
            <form-editor-input
              v-else
              :input="item"
              :input-list="childInputs"
              :first-in-row="firstInRow(index)"
              :unique-enabled="true"
              @change="(val) => updateField(val, index)"
              @remove="removeField(index)"
              @addBefore="addField(index - 1)"
              @addAfter="addField(index)"
              @edit="startEdit(item, index)"
              :allow-repeatable="false"></form-editor-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" @click="addField">
          <v-icon left>fas fa-plus-circle</v-icon>
          Add Input to Block
        </v-btn>
      </v-card-actions>
    </v-card>
    <edit-dialog
      ref="editDialog"
      :input="selectedItem"
      :input-list="editInputList"
      :allow-repeatable="false"
      :unique-enabled="true"
      @update="endEdit"></edit-dialog>
  </v-input>
</template>
<script>
import { ref, reactive, computed, watch } from '@vue/composition-api'
export default {
  components: {
    formEditorInput: () => import('./input-min'),
    HtmlBlock: () => import('./html-block'),
    EditDialog: () => import('./editDialog')
  },
  props: {
    input: {
      type: Object,
      required: true
    },
    allowRepeatable: {
      type: Boolean,
      default: true
    },
    inputList: Array
  },
  setup (props, { emit }) {
    const inputVal = reactive({
      label: props.input.label,
      input: props.input.input,
      md: props.input.md,
      lg: props.input.lg,
      settings: {
        showLabel: 'settings' in props.input && 'showLabel' in props.input.settings ? props.input.settings.showLabel : false,
        addButton: 'settings' in props.input && 'addButton' in props.input.settings ? props.input.settings.addButton : false,
        addButtonText: 'settings' in props.input && 'addButtonText' in props.input.settings ? props.input.addButtonText : '',
        min: 'settings' in props.input && 'min' in props.input.settings ? props.input.settings.min : 0,
        removable: 'settings' in props.input && 'removable' in props.input.settings ? props.input.settings.removable : true,
        unique: 'settings' in props.input && 'unique' in props.input.settings ? props.input.settings.unique : []
      },
      info: props.input.info || '',
      inputs: props.input.inputs || [],
      ifs: []
    })

    watch(() => props.input, (val) => {
      for (let l in val) {
        if (typeof (val[l]) === 'object') {
          if (Array.isArray(val[l])) {
            for (let i = 0; i < val[l].length; i++) {
              if (!(i in inputVal[l])) inputVal[l].push(val[l][i])
              else inputVal[l][i] = val[l][i]
            }
          } else {
            if (!(l in inputVal)) inputVal[l] = {}
            for (let k in val[l]) {
              // console.log(k + ': ' + val[l][k])
              inputVal[l][k] = val[l][k]
            }
          }
        } else {
          inputVal[l] = val[l]
        }
      }
    })
    watch(() => inputVal, () => {
      let changes = {}
      let changed = false
      for (let l in inputVal) {
        if (!(l in props.input) || inputVal[l] !== props.input[l]) {
          changes[l] = inputVal[l]
          changed = true
        }
      }
      // console.log(val)
      if (changed) {
        // console.log(changes)
        emit('change', changes)
      }
    })
    const inputTypes = ref([
      { text: 'Text Field', value: 'text' },
      { text: 'Email Address', value: 'email' },
      { text: 'Number', value: 'number' },
      { text: 'Multi-line Text Field', value: 'textarea' },
      { text: 'Select Box', value: 'select' },
      { text: 'Combo Box', value: 'combobox' },
      { text: 'AutoComplete', value: 'autocomplete' },
      { text: 'Radio Group', value: 'radio' },
      { text: 'Checkbox', value: 'checkbox' },
      { text: 'Switch', value: 'switch' },
      { text: 'Slider', value: 'slider' },
      { text: 'Range Slider (Dual Slider)', value: 'rangeSlider' },
      { text: 'Date Picker', value: 'datePicker' },
      { text: 'Time Picker', value: 'timePicker' },
      { text: 'HTML/Text Block', value: 'html-block' },
      { text: 'File Upload', value: 'file' },
      { text: 'Repeatable Block', value: 'repeatable-block' }
    ])
    const inputTypeLabel = computed(() => {
      if (inputVal.input !== '') {
        for (let i = 0; i < inputTypes.value.length; i++) {
          if (inputTypes.value[i].value === inputVal.input) return inputTypes.value[i].text
        }
      }
    })
    const visibilityRules = ref(props.input.ifs || [])
    watch(() => visibilityRules, (val) => {
      if (JSON.stringify(props.input.ifs) !== JSON.stringify(visibilityRules.value)) {
        emit('change', { ifs: visibilityRules.value })
      }
    })

    const widthOptions = ref([
      { text: 'Full', md: null, lg: null },
      { text: 'Half (full on small)', md: 6, lg: 6 },
      { text: 'Third (full on small)', md: 4, lg: 4 },
      { text: 'Quarter (half on medium screens, full on small)', md: 6, lg: 3 }
    ])

    const menuActive = ref(false)
    const editActive = ref(false)
    const selectedTab = ref(0)
    const fields = ref([
      { label: 'Banner ID', input: 'text', md: 4, lg: 4 },
      { label: 'First Name', input: 'text', md: 4, lg: 4 },
      { label: 'Last Name', input: 'text', md: 4, lg: 4 },
      { label: 'Middle Name', input: 'text', md: 4, lg: 4 },
      { label: 'Preferred Name', input: 'text', md: 4, lg: 4 },
      { label: 'Maiden Name', input: 'text', md: 4, lg: 4 },
      { label: 'Birth Date', input: 'date', md: 4, lg: 4 },
      {
        label: 'Address Block',
        input: 'repeatable-block',
        inputs: [
          { label: 'Address Type', input: 'text', md: 6, optionGroup: 'AddressType' },
          { label: 'Street Address', input: 'text', md: 6 },
          { label: 'City', input: 'text', md: 4, lg: 4 },
          { label: 'State', input: 'select', optionGroup: 'StateProvinceTerritory', md: 4, lg: 4 },
          { label: 'Zip', input: 'text', md: 4, lg: 4 }
        ],
        md: 12,
        lg: 12
      },
      { label: 'Street Address', input: 'text' },
      { label: 'City', input: 'text', md: 4, lg: 4 },
      { label: 'State', input: 'select', optionGroup: 'StateProvinceTerritory', md: 4, lg: 4 },
      { label: 'Zip', input: 'text', md: 4, lg: 4 },
      { label: 'Nation', input: 'select', optionGroup: 'Nation', md: 3, lg: 3 },
      {
        label: 'Email Block',
        input: 'repeatable-block',
        inputs: [
          { label: 'Email', input: 'text' }
        ]
      },
      { label: 'Email', input: 'text' },
      {
        label: 'Phone Block',
        input: 'repeatable-block',
        inputs: [
          { label: 'Phone', input: 'text', md: 6, lg: 6 },
          { label: 'Phone Type', input: 'select', optionGroup: 'PhoneType', md: 6, lg: 6 }
        ]
      },
      { label: 'Phone', input: 'text', md: 6, lg: 4 },
      { label: 'Phone Type', input: 'select', optionGroup: 'PhoneType', md: 6, lg: 6 },
      { label: 'Home Phone', input: 'text', md: 4, lg: 4 },
      { label: 'Cell Phone', input: 'text', md: 4, lg: 4 },
      { label: 'Office Phone', input: 'text', md: 4, lg: 4 }
    ])

    function updateField (obj, index) {
      let temp = Object.assign({}, inputVal.inputs[index])
      if (typeof (obj) === 'string') {
        temp.label = obj
      } else {
        for (let l in obj) {
          if (obj[l] == null || obj[l] === '' || typeof (obj[l]) === 'undefined') continue
          if (l === 'ifs' && (!Array.isArray(obj[l]) || obj[l].length === 0)) continue
          temp[l] = obj[l]
        }
      }
      inputVal.inputs.splice(index, 1, temp)
      emit('change', { inputs: inputVal.inputs })
    }
    function removeField (index) {
      inputVal.inputs.splice(index, 1)
      emit('change', { inputs: inputVal.inputs })
    }
    function addField (index) {
      let obj = {
        label: 'New Field',
        input: 'text',
        md: null,
        lg: null,
        required: false
      }
      if (isNaN(index)) index = inputVal.inputs.length - 1
      // Check to see if there is room on the row with the last input
      // This is done by counting how many past inputs have the same width, and then figuring out how many would fit on a row based on the width (width options are 3, 4, or 6 which means 4, 3, or 2 per row)
      let lastWidth = null
      let count = 0
      for (let i = index; i >= 0; i--) {
        if (inputVal.inputs[i].lg == null || inputVal.inputs[i].lg === 12 || (lastWidth != null && inputVal.inputs[i].lg !== lastWidth)) break
        lastWidth = inputVal.inputs[i].lg
        count++
      }
      if (count > 0 && lastWidth != null) {
        let countPerRow = 12 / lastWidth
        if (Math.floor(count / countPerRow) !== Math.ceil(count / countPerRow)) {
          obj.md = inputVal.inputs[index].md
          obj.lg = inputVal.inputs[index].lg
        }
      }
      inputVal.inputs.splice(index + 1, 0, obj)
      emit('change', { inputs: inputVal.inputs })
    }

    // This takes the inputs prop and converts it into a list for use in visibility rules. This ignores repeatable-block, html-block, and signature-pad.
    const childInputs = computed(() => {
      let arr = []
      inputVal.inputs.forEach(({ label, input, options }) => {
        if (input === 'html-block' || input === 'signature-pad') return
        arr.push({ text: label, value: label, type: input, options })
      })
      return arr
    })

    function firstInRow (index) {
      let width = 0
      for (let i = 0; i < index; i++) {
        if (inputVal.inputs.length >= i) {
          width += inputVal.inputs[i].lg || 0
          if (width >= 12) width = 0
        }
      }
      if (width === 0) return true
      if (width + inputVal.inputs[index].lg > 12) return true
      return false
    }

    const editInputList = computed(() => {
      let arr = []
      inputVal.inputs.forEach(({ label, input, options, optionGroup, inputs }) => {
        if (input === 'repeatable-block' || input === 'html-block' || input === 'signature-pad') return
        if (input === 'radio' || input === 'select' || input === 'autocomplete' || input === 'combobox') {
          if (options) arr.push({ text: label, value: label, type: input, options })
          else if (optionGroup) arr.push({ text: label, value: label, type: input, optionGroup })
          else if (inputs) arr.push({ text: label, value: label, type: input, inputs })
        } else {
          arr.push({ text: label, value: label, type: input })
        }
      })
      return arr
    })

    const editDialog = ref(null)
    const selectedItem = ref({})
    const selectedIndex = ref(null)
    function startEdit (item, index) {
      selectedItem.value = item
      selectedIndex.value = index
      editDialog.value.openDialog()
    }
    function endEdit (val) {
      updateField(val, selectedIndex.value)
      selectedItem.value = {}
      selectedIndex.value = null
    }

    return {
      inputVal,
      inputTypes,
      inputTypeLabel,
      visibilityRules,
      widthOptions,
      menuActive,
      editActive,
      selectedTab,
      fields,
      updateField,
      removeField,
      addField,
      childInputs,
      firstInRow,
      editInputList,
      editDialog,
      selectedItem,
      selectedIndex,
      startEdit,
      endEdit
    }
  }
}
</script>
